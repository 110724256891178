





































































































::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
